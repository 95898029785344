import { makeStyles } from '@mui/styles';
import { Fragment } from 'react';
import {
    Show, TextField, TabbedShowLayout, Tab, Datagrid,
    ReferenceManyField, ShowButton, TopToolbar,
    RefreshButton, List, useShowContext, useTranslate,
    usePermissions, BulkDeleteWithConfirmButton, ReferenceField,
    useRecordContext, FunctionField, useShowController
}
    from 'react-admin';
import Button from '@mui/material/Button';
import { ShowIpAddress } from '../../components/ipAddressField';
import { Link, useLocation } from 'react-router-dom';
import {
    ControlVm, CreateInstanceTemplate, OpenGuacConsole,
    OpenVmConsole, RebootVm, StartAllVms, StopAllVms
} from '../vms/vmControl';
import VmCreate from '../workspaces/vmCreate';
import DisAssociateUserToPod from './disassociatePodToUser';
import VolumeCreateInDialog from '../volumes/volumeCreateInDialog';
import { Pagination, Tooltip } from '@mui/material';
import convertByte from '../../utils/convertByte';
import DeployInstanceTemplate from '../pods/instanceTemplateDeploy';
import BasicBreadcrumbs from '../../components/breadcrumb';
import { Visibility } from '@material-ui/icons';
import ShowRouterInterfaces from '../routers/routerInterfaceList';
import { StatusField } from '../../custom';

const useStyles = makeStyles((theme) => ({
    tableLastCell: {
        textAlign: 'right',
        padding: '6px 6px'
    },
    button: {
        minWidth: '10px',
        width: '36px',
        height: '32px'
    },
    room: {
        color: "rgba(0, 0, 0, 0.54)",
        fontWeight: "700",
        fontSize: "0.80em",
    },
    nameCell: {
        width: '150px',  // Adjust width for name column
        maxWidth: '150px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        font: 'inherit',
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
}));

const ListActionButtons = (type) => {
    const translate = useTranslate();
    const vm = useRecordContext();
    const location = useLocation();
    const classes = useStyles();

    const firewallPath = location.pathname.replace('/firewalls', '');

    return (
        <Fragment>
            <span onClick={(event) => event.stopPropagation()}>
                <Tooltip title="Show" arrow placement="top">
                    <span style={{ width: '100%' }}>
                        <Button size='large' className={classes.button}
                            component={Link}
                            to={type.type === "firewall"
                                ? `${firewallPath}/instances/${vm.id}`
                                : `${location.pathname}/instances/${vm.id}`
                            }
                            startIcon={<Visibility />}
                        ></Button>
                    </span>
                </Tooltip>
                <ControlVm action={translate('app.vms.action.start')} />
                <ControlVm action={translate('app.vms.action.stop')} />
                <ControlVm action={translate('app.vms.action.suspend')} />
                <ControlVm action={translate('app.vms.action.resume')} />
                <RebootVm />
                <OpenVmConsole />
                <OpenGuacConsole />
                <CreateInstanceTemplate />
            </span>
        </Fragment>
    );
};

const UserListActionButtons = () => {
    return (
        <Fragment>
            <DisAssociateUserToPod />
            <ShowButton />
        </Fragment>
    );
};

const VmBulkActionButtons = (props) => (
    <Fragment>
        <BulkDeleteWithConfirmButton />
    </Fragment>
);

const SecurityGroupActions = () => {
    return (
        <RefreshButton />
    )
};

//Add deploy from instance template
const Actions = (props) => {
    const { permissions } = usePermissions();
    return (
        <TopToolbar>
            {!(permissions.length === 1 && permissions.includes('trainee')) ?
                <>
                    <VmCreate pod={props.data} />
                    <DeployInstanceTemplate pod={props.data} />
                    <StartAllVms pod={props.data} />
                    <StopAllVms pod={props.data} />
                </> : null}
            <RefreshButton />
        </TopToolbar >
    )
};

const VolumesActions = (props) => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const {
        error,  // error returned by dataProvider when it failed to fetch the record. 
        isLoading, // boolean that is true until the record is available for the first time
        record, // record fetched via dataProvider.getOne() based on the id from the location
    } = useShowContext();

    if (isLoading) {
        return <div>{translate('app.LoadingMessage')}</div>;
    }
    if (error) {
        return <div>{translate('app.ErrorMessage')}</div>;
    }

    return (
        <TopToolbar>
            {!(permissions.length === 1 && permissions.includes('trainee')) ?
                <VolumeCreateInDialog pod={{ "id": record.id, "podName": record.name }} /> : null}
            <RefreshButton />
        </TopToolbar>
    )
};

const CustomPagination = (props) => (
    <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);


const InstanceShowLayout = ({ type }) => {
    const {
        error,  // error returned by dataProvider when it failed to fetch the record. 
        isLoading, // boolean that is true until the record is available for the first time
        record, // record fetched via dataProvider.getOne() based on the id from the location
    } = useShowContext();

    const classes = useStyles();
    const translate = useTranslate();
    const CustomEmpty = () => <div>{translate('app.emptyMessage')}</div>;

    if (isLoading) {
        return <div>{translate('app.LoadingMessage')}</div>;
    }
    if (error) {
        return <div>{translate('app.ErrorMessage')}</div>;
    }

    const FlavorField = (record) => {
        return (
            <Tooltip componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: 'white',
                        color: '#6b489d',
                        fontSize: '1em',
                        padding: '6px 16px',
                        border: '2px solid #dadde9',
                    },
                },
            }} title=
                {
                    <span>
                        <strong>{record.name}</strong>
                        <div> <li> vcpus:{record.vcpus} </li> </div>
                        <div> <li> ram:{convertByte(record.ram, 2, "MB")} </li> </div>
                        <div> <li> size:{record.disk}GB </li>  </div>
                    </span>
                }>
                <span> <strong> {record.name} </strong> </span>
            </Tooltip >
        );
    };

    let customFilter = type === 'vm'
        ? { type: { $ne: "firewall" } }
        : { type: { $eq: "firewall" } };

    return (
        <>
            <List actions={type !== "firewall" && record ? <Actions data={{ id: record.id, podName: record.name, type: record.type }} /> : false}
                pagination={false}>
                <ReferenceManyField
                    label={false}
                    perPage={25}
                    reference="vms"
                    target="pod"
                    filter={customFilter}
                >
                    <Datagrid empty={<CustomEmpty />}
                        bulkActionButtons={type !== 'firewall' ? <VmBulkActionButtons /> : false}>
                        <FunctionField
                            label={translate(`app.pods.name`)}
                            render={record => (
                                <Link to={`/vms/${record.id}/show`} className={classes.nameCell}>
                                    {record.name}
                                </Link>
                            )}
                        />
                        <TextField source="description" label={translate('app.pods.description')} />
                        <ReferenceField source='flavor' reference='flavors' link='show'>
                            <FunctionField label='Name' render={FlavorField} />
                        </ReferenceField>
                        <ShowIpAddress label={translate('app.pods.ipAddress')} />
                        <TextField source="guest_OS" label={translate('app.pods.guestOS')} />
                        <StatusField source="osServerStatus" label={translate('app.vms.status')} />
                        <ListActionButtons type={type} cellClassName={classes.tableLastCell} />
                    </Datagrid>
                </ReferenceManyField>
            </List>
        </>
    );
};

const PodShow = ({ ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const CustomEmpty = () => <div>{translate('app.emptyMessage')}</div>;
    const { permissions } = usePermissions();
    const { error, isLoading, record } = useShowController({ resource: 'pods', id: props.id });
    let syncWithLocationValue = true;
    //const CustomPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

    if (isLoading) return <div>{translate('app.LoadingMessage')}</div>;

    if (error) return <div>{translate('app.ErrorMessage')}</div>;

    const podType = record.type;

    if (props.inClassroom) {
        syncWithLocationValue = false;
    }

    return (
        <>
            {props.inClassroom ? '' : <BasicBreadcrumbs name={record.name} />}

            <Show {...props}>
                <TabbedShowLayout syncWithLocation={syncWithLocationValue}>

                    <Tab label={translate('app.pods.instances')} >
                        <InstanceShowLayout type='vm' />
                    </Tab>

                    {!(permissions.length === 1 && permissions.includes('trainee')) ?
                        <Tab label={translate('app.pods.networks')} path='networks' >
                            <ReferenceManyField
                                label={false}
                                reference="networks"
                                target="pod"
                            >
                                <Datagrid>
                                    <TextField source="name" label={translate('app.pods.name')} />
                                    <TextField source="description" label={translate('app.pods.description')} />
                                    <TextField source="cidr" />
                                    <TextField source="osNetworkStatus" label={translate('app.pods.status')} />
                                </Datagrid>
                            </ReferenceManyField>

                        </Tab>
                        : null
                    }

                    {!(permissions.length === 1 && permissions.includes('trainee')) ?
                        <Tab label={translate('app.pods.routers')} path='routers' >
                            <ReferenceManyField
                                label={false}
                                reference="routers"
                                target="pod"
                            >
                                <Datagrid empty={<CustomEmpty />}>
                                    <TextField source="name" label={translate('app.pods.name')} />
                                    <TextField source="description" label={translate('app.pods.description')} />
                                    <TextField source="osRouterStatus" label={translate('app.routers.status')} />
                                    <ShowRouterInterfaces label='Interfaces' />
                                </Datagrid>
                            </ReferenceManyField>
                        </Tab> : null
                    }

                    {!(permissions.length === 1 && permissions.includes('trainee')) ?
                        <Tab label={translate('app.pods.volumes')} path='volumes'>
                            <List actions={<VolumesActions />} pagination={false} >

                                <ReferenceManyField
                                    label={false}
                                    reference="volumes"
                                    target="pod"
                                    perPage={25}
                                >
                                    <Datagrid empty={<CustomEmpty />}>
                                        <TextField source="name" label={translate('app.pods.name')} />
                                        <TextField source="description" label={translate('app.pods.description')} />
                                        <TextField source="device" label={translate('app.vms.show.attachedTo')} />
                                        <TextField source="size" label={translate('app.pods.size')} />
                                        <TextField source="osVolumeStatus" label={translate('app.pods.status')} />
                                    </Datagrid>
                                </ReferenceManyField>
                            </List>
                        </Tab> : null
                    }

                    <Tab label={translate('app.pods.firewalls')} path='firewalls'>
                        <InstanceShowLayout type='firewall' />
                    </Tab>

                </TabbedShowLayout>

            </Show >
        </>
    );
};

export default PodShow;