import axios from "axios";
import ButtonMui from '@material-ui/core/Button';
import {
    SimpleForm, useRecordContext, useTranslate, useNotify,
    useRefresh, Toolbar, SaveButton, ReferenceInput,
    Button, SelectInput, AutocompleteInput, useGetManyReference
}
    from 'react-admin';
import {
  Description, Cancel, AddLink, LinkOff,
}
    from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { SectionField } from "../../components";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { Tooltip } from "@mui/material";
import handleError from "../../components/handleError";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    spacer: {
        flex: 1,
    },
    buttonMarginLeft: {
        marginLeft: '30'
    },
    flexBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputMarginLeft: {
        marginLeft: 40
    },
    color: {
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        border: '1px solid #6b489d'
    },
}));

const AttachVolumeToVm = (props) => {

    const notify = useNotify();
    const record = useRecordContext();
    const refresh = useRefresh();
    const classes = useStyles();
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const Validate = (values) => {
        const errors = {};
        if (!values.name) errors.name = 'ra.validation.required';
        return errors;
    };

    const CreateToolbar = () => {
        return (
            <Toolbar className={classes.toolbar}>
                <Button color='primary' label="ra.action.cancel" size="medium" onClick={handleCloseClick} sx={{ border: '1px solid #6b489d' }}>
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.send')} sx={{ marginLeft: 2 }} />
            </Toolbar>
        );
    };

    const sendAttachVolume = async (data) => {

        try {
            const vmId = record.id;

            const options = {
                headers: {},
            };

            const sendData = { volumeId: data.volume };
            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;

            const res = await axios.post(`/api/v1/vms/${vmId}/volumes`, sendData, options);

            if (res.status !== 201) {
                notify(translate('app.vms.control.volume.errorMessage'), { type: 'error' });
            }
            else {
                notify(translate('app.vms.control.volume.successMessage'), { type: 'success' });
                handleCloseClick();
                refresh();
            };
        }
        catch (error) {
            handleError(error, notify);
        }
    }

    return (
        <>
            <Tooltip title={translate('app.vms.control.volume.titleAttach')} arrow placement="top">
                <ButtonMui color="primary" onClick={handleClick}>
                    <AddLink />{translate('app.vms.action.attachVolume')}
                </ButtonMui>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.vms.control.volume.titleAttach')}
            >
                <DialogTitle><SectionField label={translate('app.vms.control.volume.titleAttach')} icon={AddLink} /></DialogTitle>
                <DialogContent>
                    <SimpleForm validate={Validate} toolbar={<CreateToolbar />} onSubmit={sendAttachVolume} >

                        <ReferenceInput
                            source="volume"
                            reference="volumes"
                            filter={{ "osVolumeStatus": "available" }}
                            label={translate('app.vms.attach.volume')}
                            emptyValue={0}
                        >
                            <SelectInput optionText="name" emptyValue={0} />
                        </ReferenceInput>
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
}

const DetachVolumeToVm = (props) => {

    const notify = useNotify();
    const record = useRecordContext();
    const refresh = useRefresh();
    const classes = useStyles();
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const Validate = (values) => {
        const errors = {};
        //if (!values.volume) errors.volume = 'ra.validation.required';
        return errors;
    };

    const CreateToolbar = () => {
        return (
            <Toolbar className={classes.toolbar}>
                <Button color='primary' label="ra.action.cancel" size="medium" onClick={handleCloseClick} sx={{ border: '1px solid #6b489d' }}>
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.send')} sx={{ marginLeft: 2 }} />
            </Toolbar>
        );
    };

    const sendDetachVolume = async (data) => {
        
        try {
            const vmId = record.id;

            const options = {
                headers: {},
            };

            
            const volumeId = data.volume;
            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;

            const res = await axios.delete(`/api/v1/vms/${vmId}/volumes/${volumeId}`, options);

            if (res.status !== 201) {
                notify(translate('app.vms.control.volume.errorMessage'), { type: 'error' });
            }
            else {
                notify(translate('app.vms.control.volume.successMessage'), { type: 'success' });
                handleCloseClick();
                refresh();
            };
        }
        catch (error) {
            handleError(error, notify);
        }
    }

    const VolumeField = () => {

        const { data, total, isLoading } = useGetManyReference("volumes", {
            target: "_id",
            id: record.volumes,
            sort: { field: 'id', order: 'ASC' }
        });

        return (
            <AutocompleteInput source="volume" style={{ minWidth: 250 }} optionText="name"  label={translate('app.vms.control.volume.list')}
                choices={data} />

        );

    };


    return (
        <>
            <Tooltip title={translate('app.vms.control.volume.titleDetach')} arrow placement="top">
                <ButtonMui color="primary" onClick={handleClick}>
                    <LinkOff />{translate('app.vms.action.detachVolume')}
                </ButtonMui>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.vms.control.volume.titleDetach')}
            >
                <DialogTitle>{translate('app.vms.control.volume.titleDetach')}</DialogTitle>
                <DialogContent>
                    <SimpleForm validate={Validate} toolbar={<CreateToolbar />} onSubmit={sendDetachVolume} >
                        <SectionField label={translate('app.vms.control.volume.data')} icon={Description} />
                        <VolumeField />

                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
}


export { AttachVolumeToVm, DetachVolumeToVm };