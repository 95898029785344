import { Box, Grid } from '@mui/material';
import {
    TextField, ReferenceField,
    useTranslate, ArrayField, Datagrid, ReferenceArrayField,
    DeleteWithConfirmButton, usePermissions, FunctionField,
    TabbedShowLayout, Tab, SingleFieldList,
}
    from 'react-admin';
import { AddressField } from '../components/ipAddressField';
import { SectionField } from '../components';
import { FilterCenterFocus } from '@material-ui/icons';
import {
    ControlVm, RebootVm, ResizeVm, CreateVmInterface,
    RemoveVmInterface, OpenVmConsole,
    CreateInstanceTemplate,
    OpenGuacConsole
} from '../views/vms/vmControl';
import { AttachVolumeToVm, DetachVolumeToVm, } from '../views/volumes/handleVolume';
import { Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import convertByte from '../utils/convertByte';
import StatusField from './customStatusField';
import determineSubnet from '../components/determineSubnet';
//import InstanceShow from '../vms/vmShow';

const useStyles = makeStyles((theme) => ({
    tableLastCell: {
        width: '100%',
        textAlign: 'right'
    },
    content: {
        whiteSpace: "pre-wrap",
        margin: '10px'
    },
    nameCell: {

    },
    networkCell: {
        maxWidth: '200px', // Set the maximum width of the datagrid to reduce its size
        maxHeight: '200px', // Set the maximum height of the datagrid
        '& .RaDatagrid-headerCell': {
            display: 'none',
        },
        '& .MuiTableRow-root': {
            //borderBottom: 'none',  // Removes the line between rows
        },
        '& .MuiTableCell-root': {
            border: 'none',  // Removes all cell borders
            padding: '8px', // Optional: Adjust padding for cells to make the size smaller
        }
    }
}));


const DeleteButton = (record) => {
    const translate = useTranslate();

    const title = `${translate('app.vms.delete.title')} ${record.record.name}`;

    //translateOptions={{ id: ' ', name: record.record.name }}
    return (
        <DeleteWithConfirmButton confirmContent={translate('app.vms.delete.confirmMessage')}
            confirmTitle={title} />
    )
};


const ListActionButtons = (data) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();

    return (
        <Fragment>
            {!(permissions.length === 1 && permissions.includes('trainee')) ?
                <>
                    <ControlVm action={translate('app.vms.action.start')} showTitle='yes' />
                    <ControlVm action={translate('app.vms.action.stop')} showTitle='yes' />
                    <ControlVm action={translate('app.vms.action.suspend')} showTitle='yes' />
                    <ControlVm action={translate('app.vms.action.resume')} showTitle='yes' />
                    <RebootVm showTitle='yes' />
                    <ResizeVm showTitle='yes' />
                    <CreateVmInterface />
                    <RemoveVmInterface />
                    <OpenGuacConsole showTitle='yes' />
                    <OpenVmConsole showTitle='yes' />
                    <CreateInstanceTemplate showTitle='yes' />
                    <AttachVolumeToVm />
                    <DetachVolumeToVm />
                </>
                :
                <>
                    <ControlVm action={translate('app.vms.action.start')} showTitle='yes' />
                    <ControlVm action={translate('app.vms.action.stop')} showTitle='yes' />
                    <ControlVm action={translate('app.vms.action.suspend')} showTitle='yes' />
                    <ControlVm action={translate('app.vms.action.resume')} showTitle='yes' />
                    <RebootVm showTitle='yes' />
                    <OpenGuacConsole showTitle='yes' />
                    <OpenVmConsole showTitle='yes' />
                </>
            }

        </Fragment>
    );
};

const InstanceShow = ({ record }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const style = { 'fontSize': '0.90em', 'color': 'rgba(0, 0, 0, 0.7)', 'fontWeight': '700' };

    const flavorField = record =>
        <span>
            <strong> {record.name}</strong>
            <div>  vcpus: {record.vcpus} </div>
            <div>  ram: {convertByte(record.ram, 2, 'MB')} </div>
            <div>  size: {record.disk}GB </div>
        </span>;

    const CustomEmpty = () => <div>{translate('app.emptyMessage')}</div>;

    const subnetField = record => record.addressIp ? determineSubnet(record.addressIp) : "";

    return (
        <>
            <SectionField label={<TextField source='name' sx={{ fontSize: ' 1.5rem' }} />} icon={FilterCenterFocus} />
            <div> </div>
            <ListActionButtons type={record.type} className={classes.tableLastCell} />
            <SectionField label={' '} />

            <TabbedShowLayout syncWithLocation={false}>
                <Tab label='Overview'>
                    <Grid container spacing={3}>

                        <Grid item xs={4}>
                            <Box>
                                <span style={style}>{translate('app.vms.name')} </span> <br />
                                <TextField source='name' label={translate('app.vms.name')} />
                            </Box>
                            <Box>
                                <br /> <span style={style}>{translate('app.vms.description')} </span> <br />
                                <TextField source='description' label={translate('app.vms.description')} />
                            </Box>
                            <Box>
                                <br /> <span style={style}>Flavors </span> <br />
                                <ReferenceField source='flavor' reference='flavors' link='show'>
                                    <FunctionField label='Name' render={flavorField} />
                                </ReferenceField>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <span style={style}>{translate('app.vms.guestOS')}</span> <br />
                                <TextField source='guest_OS' label={translate('app.vms.guestOS')} />
                            </Box>
                            <Box>
                                <br /> <span style={style}>Pod</span> <br />
                                <ReferenceField source='pod' reference='pods' link='show'>
                                    <TextField source='name' />
                                </ReferenceField>
                            </Box>
                            {
                                record.instanceTemplate ? <Box>
                                    <br /> <span style={style}>{translate('app.vms.show.instanceTemplate')}</span> <br />
                                    <ReferenceField source='instanceTemplate' reference='instanceTemplates' link='show'>
                                        <TextField source='name' />
                                    </ReferenceField>
                                </Box> : ''
                            }
                            <Box>
                                <br /> <span style={style}>{translate('app.vms.show.status')} </span> <br />
                                <StatusField source="osServerStatus" label={translate('app.vms.status')} />
                            </Box>
                            <Box>
                                {record && Array.isArray(record.errorMessage) && record.errorMessage.length > 0 ?
                                    <TextField source='errorMessage' label={translate('app.vms.show.error')} />
                                    :
                                    null
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <span style={style}>{translate('app.volumes.title')}</span> <br />
                                <ReferenceArrayField
                                    label={translate('app.volumes.title')}
                                    reference='volumes'
                                    source='volumes'
                                >
                                    <SingleFieldList>
                                        <TextField className={classes.content} source="name" />
                                    </SingleFieldList>
                                </ReferenceArrayField>
                            </Box>
                            <Box>
                                <br /><span style={style}>{translate('app.vms.language')}</span> <br />
                                <FunctionField
                                    label={translate('app.vms.language')}
                                    render={record => {
                                        switch (record.language) {
                                            case 'en':
                                                return 'English';
                                            case 'fr':
                                                return 'French';
                                            default:
                                                return record.language; // In case there's a value you haven't mapped
                                        }
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <div> </div>
                </Tab>


                <Tab label='Interfaces'>
                    <AddressField label={translate('app.vms.show.interface')} />
                    <Box>
                        <br /> <span style={style}>{translate('app.vms.networks')}</span> <br />
                        <ArrayField source='netifs' label={false}>
                            <Datagrid bulkActionButtons={false}
                                className={classes.networkCell}>
                                <FunctionField label='Network' render={subnetField} linkType={false} />
                            </Datagrid>
                        </ArrayField>
                    </Box>
                </Tab>

                <Tab label='Volumes'>
                    {record && record.volumes ?
                        <ReferenceArrayField
                            label={translate('app.volumes.title')}
                            reference='volumes'
                            source='volumes'
                        >
                            <Datagrid empty={<CustomEmpty />} bulkActionButtons={false}>
                                <TextField source='name' label={translate('app.vms.name')} />
                                <TextField source='description' label={translate('app.vms.description')} />
                                <TextField source='device' label={translate('app.vms.show.attachedTo')} />
                            </Datagrid>
                        </ReferenceArrayField>
                        :
                        <ArrayField source='osVolumes_attached'>
                            <Datagrid>
                                <TextField source='id' />
                            </Datagrid>
                        </ArrayField>
                    }

                    {record && record.imageRef ?
                        <ReferenceField source='imageRef' reference='images' link='show' label={translate('app.vms.show.mediaDevice')}>
                            <TextField source='name' />
                        </ReferenceField>
                        :
                        null
                    }

                    {record && record.block_devices ?
                        record.block_devices.map(blockDevice => (
                            <li color='primary' key={blockDevice.sourceId} label={blockDevice.source_type}> {blockDevice.source_type} </li>
                        ))
                        :
                        null
                    }
                </Tab>
            </TabbedShowLayout >
        </>
    )
};

export default InstanceShow;