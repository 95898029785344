import { createRef, useState } from 'react';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    ControlVm, RebootVm, ResizeVm,
    OpenVmConsole,
    CreateInstanceTemplate,
    OpenGuacConsole
} from '../views/vms/vmControl';
import { useTranslate } from 'react-admin';
import { MenuItem } from '@mui/material';
import StyledMenu from './styledMenu';


const ActionsInstances = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const translate = useTranslate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        //inputRef.current = event.currentTarget;
    };
    const handleClose = () => {
        setTimeout(() => setAnchorEl(null), 100); // Delay to prevent event conflicts
    };

    return (
        <>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                Actions
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                    //sx: { width: anchorEl && anchorEl.offsetWidth } // set width equal to the button's width
                }}
                anchorEl={anchorEl}
                open={open}
                keepMounted
                onBlur={handleClose}
                onClose={handleClose}
            >
                <MenuItem style={{ width: '100%' }}  disableRipple>
                    <ControlVm action={translate('app.vms.action.start')} showTitle='True' fullWidth />
                </MenuItem>
                <MenuItem style={{ width: '100%' }} disableRipple>
                    <ControlVm action={translate('app.vms.action.stop')} showTitle='True' fullWidth/>
                </MenuItem>
                <MenuItem  disableRipple>
                    <ControlVm action={translate('app.vms.action.suspend')} showTitle='True' fullWidth />
                </MenuItem>
                <MenuItem disableRipple>
                    <ControlVm action={translate('app.vms.action.resume')} showTitle='True' fullWidth />
                </MenuItem>
                <MenuItem style={{ width: '100%' }} disableRipple>
                    <RebootVm showTitle='True' fullWidth />
                </MenuItem>
                <MenuItem style={{ width: '100%' }} onClick={handleClose} disableRipple>
                    <ResizeVm fullWidth />
                </MenuItem>
                <MenuItem style={{ width: '100%' }} onClick={handleClose} disableRipple>
                    <OpenGuacConsole fullWidth showTitle='True'  />
                </MenuItem>
                <MenuItem style={{ width: '100%' }} onClick={handleClose} disableRipple>
                    <OpenVmConsole fullWidth showTitle='True' />
                </MenuItem>
                <MenuItem style={{ width: '100%' }} onClick={handleClose} disableRipple>
                    <CreateInstanceTemplate fullWidth showTitle='True' />
                </MenuItem>
            </StyledMenu>
        </>
    );
}

export default ActionsInstances;