import axios from "axios";
import ButtonMui from '@material-ui/core/Button';
import {
    AutocompleteInput, SimpleForm, useRecordContext, useTranslate, useNotify,
    useRefresh, Toolbar, SaveButton, RadioButtonGroupInput, ReferenceInput,
    FormDataConsumer, TextInput, Button, SelectInput, useRedirect, Loading,
    useGetList, Confirm, PasswordInput,
    ArrayInput,
    SimpleFormIterator
}
    from 'react-admin';
import {
    PlayCircleFilled, StopCircle, PauseCircleFilled, NotStarted,
    RestartAlt, Description, HourglassDisabled, Cancel, AspectRatio, Lan, PortableWifiOff,
    Laptop, CloudDownload, SearchOff, Start
}
    from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import { SectionField, ViewTitle } from "../../components";
import { makeStyles } from "@material-ui/styles";
import { Fragment, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import { HandleError } from "../../components";

let buttonPrimaryColor = '#6b489d';
const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    spacer: {
        flex: 1,
    },
    buttonMarginLeft: {
        marginLeft: '30'
    },
    flexBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputMarginLeft: {
        marginLeft: 40
    },
    color: {
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        border: '1px solid #6b489d'
    },
    buttonColor: {
        color: theme.palette.primary.main,
    },
    button: {
        minWidth: '10px',
        //width: '100%',
        //justifyContent: 'left'
    }
}));

const ControlVm = (props) => {

    const notify = useNotify();
    const record = useRecordContext();
    const refresh = useRefresh();
    const translate = useTranslate();
    const classes = useStyles();
    const action = props.action;
    let request;
    const showTitle = props.showTitle;
    let Icon = null;
    let buttonColor = ''; // Default color

    let isDisabled = record.osServerStatus === 'REBOOT' || record.osServerStatus === 'BUILD'; // Disable all buttons if VM is in REBOOT or BUILD status

    // Determine icon, request type, and color based on action
    if (action === translate('app.vms.action.start')) {
        Icon = PlayCircleFilled;
        request = 'start';
        buttonColor = record.osServerStatus === 'ACTIVE' || isDisabled ? '#CCCCCC' : '#4CAF50';
        isDisabled = isDisabled || record.osServerStatus === 'ACTIVE';
    } else if (action === translate('app.vms.action.stop')) {
        Icon = StopCircle;
        request = 'stop';
        buttonColor = record.osServerStatus === 'SHUTOFF' || isDisabled ? '#CCCCCC' : '#F44336';
        isDisabled = isDisabled || record.osServerStatus === 'SHUTOFF';
    } else if (action === translate('app.vms.action.suspend')) {
        Icon = PauseCircleFilled;
        request = 'suspend';
        buttonColor = record.osServerStatus !== 'ACTIVE' || isDisabled ? '#CCCCCC' : '#FF9800';
        isDisabled = isDisabled || record.osServerStatus !== 'ACTIVE';
    } else if (action === translate('app.vms.action.resume')) {
        Icon = NotStarted;
        request = 'resume';
        buttonColor = record.osServerStatus === 'SUSPENDED' ? buttonPrimaryColor : '#CCCCCC'; // Grey if not suspended
        isDisabled = record.osServerStatus !== 'SUSPENDED'; // Disable if VM is not suspended
    }

    const handleClick = async () => {

        try {
            const vmId = record.id;

            const options = {
                headers: {},
            };

            const data = "";
            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;

            const res = await axios.post(`/api/v1/vms/${vmId}/${request}`, data, options);

            if (res.status !== 200) {
                notify(`Cannot ${action} instance`, { type: 'error' });
            }
            else {
                notify(`${action} Instance`, { type: 'success' });
                refresh();
            };
        }
        catch (error) {
            HandleError(error, notify);
        };
    }


    return (
        <>
            {Icon ?
                <Tooltip title={action} arrow placement="top">
                    <span style={{ width: '100%' }}>
                        <ButtonMui color="primary" onClick={handleClick} className={classes.button} fullWidth={props.fullWidth}
                            disabled={isDisabled} // Disable the button based on osServerStatus
                            style={{ color: isDisabled ? '#888888' : buttonColor }} // Set custom background color
                        >
                            <Icon style={{ color: isDisabled ? '#888888' : buttonColor }} />{showTitle ? action : null}
                        </ButtonMui>
                    </span>
                </Tooltip> : null}
        </>
    );
};

const StopAllVms = (props) => {

    const notify = useNotify();
    const classes = useStyles();
    const translate = useTranslate();
    const expected_status = "SHUTOFF";
    const [showloading, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    let status_res = false;
    const refresh = useRefresh();

    const HandleConfirm = async () => {

        try {
            const podId = props.pod.id;
            setShow(true);
            setOpen(false);
            const options = {
                headers: {},
            };
            const data = "";
            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;
            const res = await axios.post(`/api/v1/vms/${podId}/stopAll`, data, options);

            if (res.status !== 200) {
                notify(translate('app.vms.control.stopAll.errorMessage'), { type: 'error' });
                setShow(false);
            } else {
                status_res = await axios.post(`/api/v1/vms/${podId}/checkAll/${expected_status}`, data, options);
                if (status_res.status !== 200) {
                    setShow(false);
                }
                if (status_res.data) {
                    notify(translate('app.vms.control.stopAll.successMessage'), { type: 'success' });
                    refresh();
                } else {
                    notify(translate('app.vms.control.stopAll.errorMessage'), { type: 'error' });
                };
            };
            setShow(false);
        }
        catch (error) {
            setShow(false);
            HandleError(error, notify);

        };

    }
    /* For confirmation before shutting down everything */
    const handleDialogClose = () => {
        setOpen(false);
    };
    const HandleClick = () => {
        setOpen(true);
    };

    return (
        <Fragment>
            {
                showloading !== true ?
                    <Button label={translate('app.pods.stop.buttonInWorkspace')} onClick={HandleClick} className={classes.color}><Cancel /></Button>
                    :
                    <Button disabled={true} label={translate('loading')} onClick={HandleClick} className={classes.color}><SearchOff /> </Button>

            }
            <Confirm
                isOpen={open}
                title={<ViewTitle label={translate('app.vms.control.stopAll.title')} icon={Cancel} />}
                content={translate('app.vms.control.stopAll.confirmMessage')}
                onConfirm={HandleConfirm}
                onClose={handleDialogClose} />

        </Fragment>

    )
}

const StartAllVms = (props) => {

    const notify = useNotify();
    const classes = useStyles();
    const translate = useTranslate();
    const [showloading, setShow] = useState(false);
    const [open, setOpen] = useState(false)
    const expected_status = "ACTIVE";
    const refresh = useRefresh();
    let status_res = false;

    const HandleConfirm = async () => {
        try {
            const podId = props.pod.id;

            setShow(true);
            setOpen(false);
            const options = {
                headers: {},
            };
            const data = "";
            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;
            const res = await axios.post(`/api/v1/vms/${podId}/startAll`, data, options);

            if (res.status !== 200) {
                notify(translate('app.vms.control.startAll.errorMessage'), { type: 'error' });
            } else {

                status_res = await axios.post(`/api/v1/vms/${podId}/checkAll/${expected_status}`, data, options);
                if (status_res.status !== 200) {
                    setShow(false);
                }
                if (status_res.data) {
                    notify(translate('app.vms.control.startAll.successMessage'), { type: 'success' });
                    refresh();
                } else {
                    notify(translate('app.vms.control.startAll.errorMessage'), { type: 'error' });
                };
                setShow(false);
            };

        }
        catch (error) {
            setShow(false);
            HandleError(error, notify);

        };


    }
    /* For confirmation before shutting down everything */
    const HandleClick = () => {
        setOpen(true);
    };
    const handleDialogClose = () => {
        setOpen(false);
    }
    return (
        <Fragment>

            {showloading !== true ?
                <Button label={translate('app.pods.start.buttonInWorkspace')}
                    onClick={HandleClick} className={classes.color}>
                    <Start />
                </Button>

                :
                <Button disabled={true} label={translate('loading')} className={classes.color}><HourglassDisabled /> </Button>

            }
            <Confirm
                isOpen={open}
                title={<ViewTitle label={translate('app.vms.control.startAll.title')} icon={Start} />}
                content={translate('app.vms.control.startAll.confirmMessage')}
                onConfirm={HandleConfirm}
                onClose={handleDialogClose} />

        </Fragment>
    )
};

const RebootVm = (props) => {

    const notify = useNotify();
    const record = useRecordContext();
    const refresh = useRefresh();
    const classes = useStyles();
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);
    const showTitle = props.showTitle;

    // Determine if the Reboot button should be disabled
    const isDisabled = record.osServerStatus !== 'ACTIVE';

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const Validate = (values) => {
        const errors = {};
        if (!values.rebootType) errors.rebootType = 'ra.validation.required';
        return errors;
    };

    const CreateToolbar = (props) => {
        return (
            <Toolbar className={classes.toolbar}>
                <Button color='primary' label="ra.action.cancel" size="medium" onClick={handleCloseClick} sx={{ border: '1px solid #6b489d' }}>
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.send')} sx={{ marginLeft: 2 }} />
            </Toolbar>
        );
    };



    const sendReboot = async (data) => {

        try {
            const vmId = record.id;

            const options = {
                headers: {},
            };

            const sendData = { rebootType: data.rebootType };
            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;

            const res = await axios.post(`/api/v1/vms/${vmId}/reboot`, sendData, options);

            if (res.status !== 200) {
                notify(translate('app.vms.control.reboot.errorMessage'), { type: 'error' });
            }
            else {
                notify(translate('app.vms.control.reboot.successMessage'), { type: 'success' });
                handleCloseClick();
                refresh();
            };
        }
        catch (error) {
            HandleError(error, notify);
        }
    }

    return (
        <>
            <Tooltip title={translate('app.vms.control.reboot.button')} arrow placement="top">
                <span style={{ width: '100%' }}>
                    <ButtonMui color="primary" onClick={handleClick} className={classes.button} fullWidth={props.fullWidth}
                        disabled={isDisabled} // Disable the button based on osServerStatus
                        style={{
                            color: isDisabled ? '#888888' : buttonPrimaryColor
                        }}>
                        <RestartAlt style={{ color: isDisabled ? '#888888' : buttonPrimaryColor }} />
                        {showTitle ? translate('app.vms.control.reboot.button') : null}
                    </ButtonMui>
                </span>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.vms.control.reboot.title')}
            >
                <DialogTitle><SectionField label={translate('app.vms.control.reboot.title')} icon={RestartAlt} /></DialogTitle>
                <DialogContent>
                    <SimpleForm validate={Validate} toolbar={<CreateToolbar />} onSubmit={sendReboot} >
                        <RadioButtonGroupInput variant='outlined' source='rebootType' choices={[
                            { id: 'hard', name: 'hard' },
                            { id: 'soft', name: 'soft' },
                        ]} />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
};

const ResizeVm = (props) => {

    const notify = useNotify();
    const record = useRecordContext();
    const refresh = useRefresh();
    const classes = useStyles();
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);
    const filterToQuery = searchText => ({ name: `${searchText}` });

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const Validate = (values) => {
        const errors = {};
        if (!values.flavorId) errors.flavorId = 'ra.validation.required';
        return errors;
    };

    const CreateToolbar = (props) => {
        return (
            <Toolbar className={classes.toolbar}>
                <Button label="ra.action.cancel" size="medium" onClick={handleCloseClick} sx={{ border: '1px solid #6b489d' }} className={classes.button}>
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.send')} sx={{ marginLeft: 2 }} />
            </Toolbar>
        );
    };

    const sendResizeAction = async (data) => {

        try {
            const vmId = record.id;

            const options = {
                headers: {},
            };

            const sendData = { flavorId: data.flavorId };
            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;

            const res = await axios.post(`/api/v1/vms/${vmId}/resize`, sendData, options);

            if (res.status !== 200) {
                notify(translate('app.vms.control.resize.errorMessage'), { type: 'error' });

            }
            else {
                notify(translate('app.vms.control.resize.successMessage'), { type: 'success' });
                handleCloseClick();
                refresh();
            };
        }
        catch (error) {
            HandleError(error, notify);
        }
    }

    return (
        <>
            <Tooltip title={translate('app.vms.control.resize.title')} arrow placement="top">
                <ButtonMui color="primary" onClick={handleClick} className={classes.button} fullWidth={props.fullWidth}>
                    <AspectRatio />{translate('app.vms.action.resize')}
                </ButtonMui>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.vms.control.resize.title')}
            >
                <DialogTitle><SectionField label={translate('app.vms.control.resize.title')} icon={AspectRatio} /></DialogTitle>
                <DialogContent>
                    <SimpleForm validate={Validate} toolbar={<CreateToolbar />} onSubmit={sendResizeAction} >
                        <SectionField label={translate('app.vms.control.resize.data')} icon={Description} />

                        <ReferenceInput
                            source="flavorId"
                            reference="flavors"
                            label={translate('app.vms.control.resize.flavor')}
                            sort={{ field: 'name', order: 'ASC' }}
                        >
                            <AutocompleteInput optionText="name" filterToQuery={filterToQuery} />

                        </ReferenceInput>
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
};

const CreateVmInterface = (props) => {

    const notify = useNotify();
    const record = useRecordContext();
    const refresh = useRefresh();
    const classes = useStyles();
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const Validate = (values) => {
        const errors = {};
        if (!values.networkId) errors.networkId = 'ra.validation.required';
        if (!values.ipAddress) errors.ipAddress = 'ra.validation.required';
        return errors;
    };

    const CreateToolbar = (props) => {
        return (
            <Toolbar className={classes.toolbar}>
                <Button color='primary' label="ra.action.cancel" size="medium" onClick={handleCloseClick} sx={{ border: '1px solid #6b489d' }}>
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.send')} sx={{ marginLeft: 2 }} />
            </Toolbar>
        );
    };

    const sendCreateInterfaceAction = async (data) => {

        try {
            const vmId = record.id;

            const options = {
                headers: {},
            };

            const sendData = { ipAddress: data.ipAddress, networkId: data.networkId };
            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;

            const res = await axios.post(`/api/v1/vms/${vmId}/interface`, sendData, options);

            if (res.status !== 201) {
                notify(translate('app.vms.control.createInterface.errorMessage'), { type: 'error' });
            }
            else {
                notify(translate('app.vms.control.createInterface.successMessage'), { type: 'success' });
                handleCloseClick();
                refresh();
            };
        }
        catch (error) {
            HandleError(error, notify);
        }
    }

    return (
        <>
            <Tooltip title={translate('app.vms.control.createInterface.title')} arrow placement="top">
                <ButtonMui color="primary" onClick={handleClick} className={classes.button}>
                    <Lan />{translate('app.vms.action.createInterface')}
                </ButtonMui>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.vms.control.createInterface.title')}
            >
                <DialogTitle>{translate('app.vms.control.createInterface.title')}</DialogTitle>
                <DialogContent>
                    <SimpleForm validate={Validate} toolbar={<CreateToolbar />} onSubmit={sendCreateInterfaceAction} >
                        <SectionField label={translate('app.vms.control.createInterface.data')} icon={Description} />
                        <FormDataConsumer>
                            {({ formData, ...rest }) => (
                                formData.pod
                                    ?
                                    <ReferenceInput
                                        source="networkId"
                                        reference="networks"
                                        label={translate('app.vms.control.createInterface.network')}
                                        filter={{ "pod": formData.pod }}
                                    >
                                        <AutocompleteInput optionText="name" />
                                    </ReferenceInput>
                                    :
                                    <TextInput source="networks" disabled />
                            )}
                        </FormDataConsumer>
                        <TextInput source="ipAddress" />

                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
};

const RemoveVmInterface = () => {

    const notify = useNotify();
    const record = useRecordContext();
    const refresh = useRefresh();
    const classes = useStyles();
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const Validate = (values) => {
        const errors = {};
        if (!values.portId) errors.portId = 'ra.validation.required';
        return errors;
    };

    const CreateToolbar = (props) => {
        return (
            <Toolbar className={classes.toolbar}>
                <Button color='primary' label="ra.action.cancel" size="medium" onClick={handleCloseClick} sx={{ border: '1px solid #6b489d' }}>
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.send')} sx={{ marginLeft: 2 }} />
            </Toolbar>
        );
    };

    const AddressField = () => {

        if (record && record.netifs) {
            const netifs = record.netifs;
            const netifWithoutFloatingIp = netifs.filter(netif => netif.addressIp);
            return (
                <AutocompleteInput optionText="addressIp" source="portId" label={translate('app.vms.control.removeInterface.port')}
                    optionValue="osPortId" choices={netifWithoutFloatingIp} />
            );
        }
        else return null;
    };

    const sendRemoveInterfaceAction = async (data) => {

        try {
            const vmId = record.id;
            const netifs = record.netifs;
            const filterInterface = netifs.filter(netif => netif.osPortId === data.portId);

            const options = {
                headers: {},
            };

            const sendData = { portId: data.portId, ipAddress: filterInterface[0].addressIp };

            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;

            const res = await axios.delete(`/api/v1/vms/${vmId}/interface`, { data: sendData, headers: { "Authorization": `Bearer ${token}` } });

            if (res.status !== 201) {
                notify(translate('app.vms.control.removeInterface.errorMessage'), { type: 'error' });
            }
            else {
                notify(translate('app.vms.control.removeInterface.successMessage'), { type: 'success' });
                handleCloseClick();
                refresh();
            };
        }
        catch (error) {
            HandleError(error, notify);
        };
    };

    return (
        <>
            <Tooltip title={translate('app.vms.control.removeInterface.title')} arrow placement="top">
                <ButtonMui color="primary" onClick={handleClick} className={classes.button}>
                    <PortableWifiOff />{translate('app.vms.action.removeInterface')}
                </ButtonMui>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.vms.control.removeInterface.title')}
            >
                <DialogTitle>{translate('app.vms.control.removeInterface.title')}</DialogTitle>
                <DialogContent>
                    <SimpleForm validate={Validate} toolbar={<CreateToolbar />} onSubmit={sendRemoveInterfaceAction} >
                        <SectionField label={translate('app.vms.control.removeInterface.data')} icon={Description} />
                        <AddressField />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
};

const OpenVmConsole = (props) => {

    const notify = useNotify();
    const record = useRecordContext();
    const refresh = useRefresh();
    const translate = useTranslate();
    const classes = useStyles();
    const showTitle = props.showTitle;

    const CreateVmConsole = async (data) => {

        try {
            const vmId = record.id;

            const options = {
                headers: {},
            };

            const sendData = { rebootType: data.rebootType };
            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;

            const res = await axios.post(`/api/v1/vms/${vmId}/console`, sendData, options);
            if (res.status !== 201) {
                notify(translate('app.vms.control.consoleVNC.errorMessage'), { type: 'error' });
            }
            else {
                const url = res.data.remote_console.url;
                notify(translate('app.vms.control.consoleVNC.successMessage'), { type: 'success' });
                const urlWithTitle = url + '&title=' + record.name;
                if (url) window.open(urlWithTitle);
                refresh();
            };
        }
        catch (error) {
            HandleError(error, notify);
        }
    }

    return (
        <>
            <Tooltip title={translate('app.vms.control.consoleVNC.title')} arrow placement="top">
                <ButtonMui color="primary" onClick={CreateVmConsole} className={classes.button} fullWidth={props.fullWidth}>
                    <Laptop />{showTitle ? translate('app.vms.action.vncConsole') : null}
                </ButtonMui>
            </Tooltip>
        </>
    )
};

const OpenGuacConsole = (props) => {

    const notify = useNotify();
    const record = useRecordContext();
    const translate = useTranslate();
    const classes = useStyles();
    const [showDialog, setShowDialog] = useState(false);
    const userToken = localStorage.getItem('guacamoleToken');
    const showTitle = props.showTitle;

    let type;
    let port;

    const handleClick = () => {
        setShowDialog(true);
    };

    const closeDialog = () => {
        setShowDialog(false);
    };

    const CreateGuacConsole = async (data) => {

        const netifs = record.netifs;
        const netifFloatingIp = netifs.filter(netif => netif.type === "floating");
        const guestOS = record.guest_OS;
        const language = record.language ? record.language : 'en';

        if (!netifFloatingIp || netifFloatingIp.length < 1) return notify('Connection cannot be established : No floating IP', { type: 'error' });

        const floatingIp = netifFloatingIp[0].floating_ip_address;
        if (!floatingIp) return notify('Connection cannot be established : No floating IP', { type: 'error' });

        const connectionName = record.name + '_' + floatingIp;

        // Set connection type and port based on the guest OS
        if (guestOS === "windows") {
            type = "rdp";
            port = "3389";
        } else {
            type = "ssh";
            port = "22";
        };

        try {

            const options = {
                headers: {},
            };


            const sendData = {
                username: data.login,
                password: data.password,
                port: port,
                type: type,
                hostname: floatingIp,
                connectionName,
                vmLogin: data.vmLogin,
                vmPassword: data.vmPassword,
                userToken,
                language
            };

            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;

            const res = await axios.post(`/api/v1/guacamole`, sendData, options);
            if (res.status !== 200) {
                notify(translate('app.vms.control.consoleGuac.errorMessage'), { type: 'error' });
            }
            else {
                const data = res.data;
                notify(translate('app.vms.control.consoleGuac.successMessage'), { type: 'success' });
                localStorage.setItem('guacamoleToken', data.userToken);
                if (data) window.open(data.url + data.userToken);
                closeDialog();
            };
        }
        catch (error) {
            if (error.response && error.response.status === 401 && error.response.data.message === 'Authentication failed') {
                localStorage.setItem('guacamoleToken', '');
                closeDialog();
            }
            HandleError(error, notify);
        }
    };

    const CreateToolbar = (props) => {
        return (
            <Toolbar className={classes.toolbar}>
                <Button color='primary' label="ra.action.cancel" size="medium" onClick={closeDialog} sx={{ border: '1px solid #6b489d' }}>
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.send')} sx={{ marginLeft: 2 }} />
            </Toolbar>
        );
    };

    return (
        <>
            <Tooltip title={translate('app.vms.control.consoleGuac.title')} arrow placement="top">
                <ButtonMui color="primary" onClick={handleClick} className={classes.button} fullWidth={props.fullWidth}>
                    <DesktopMacIcon />{showTitle ? translate('app.vms.action.guacamole') : null}
                </ButtonMui>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={closeDialog}
                aria-label={translate('app.vms.control.consoleGuac.guacMessage')}
            >
                <DialogTitle> <SectionField label={translate('app.vms.control.consoleGuac.guacMessage')} icon={DesktopMacIcon} /></DialogTitle>
                <DialogContent>
                    <SimpleForm toolbar={<CreateToolbar handleCloseClick={closeDialog} />} onSubmit={CreateGuacConsole}>
                        {!userToken ? <>
                            <SectionField label={translate('app.vms.control.consoleGuac.UserAuthentication')} icon={AccountCircleIcon} />
                            <TextInput source='login' />
                            <PasswordInput source="password" autoComplete="off" />
                        </> : ''
                        }

                        <SectionField label={translate('app.vms.control.consoleGuac.VMAuthentication')} icon={AccountCircleIcon} />
                        <TextInput source='vmLogin' autoComplete="new-password" />
                        <PasswordInput source="vmPassword" autoComplete="new-password" />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
};

const CreateInstanceTemplate = (props) => {

    const notify = useNotify();
    const record = useRecordContext();
    const refresh = useRefresh();
    const classes = useStyles();
    const translate = useTranslate();
    const redirect = useRedirect();
    const [showDialog, setShowDialog] = useState(false);
    const showTitle = props.showTitle;
    let buttonColor = '#007FFF';

    const fixedNetifs = record?.netifs?.filter(netif => netif.type === 'fixed') || [];

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const Validate = (values) => {
        const errors = {};
        if (!values.name) errors.name = 'ra.validation.required';
        return errors;
    };

    const CreateToolbar = (props) => {
        return (
            <Toolbar className={classes.toolbar}>
                <Button label="ra.action.cancel" size="medium" onClick={handleCloseClick} sx={{ border: '1px solid #6b489d' }}>
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.send')} sx={{ marginLeft: 2 }} />
            </Toolbar>
        );
    };

    const instanceTemplateSave = async (data) => {

        try {
            const vmId = record.id;

            const options = {
                headers: {},
            };

            const sendData = {
                "name": data.imageName,
                "description": data.description,
                "language": data.language,
                "netifs": data.fixedNetifs  // Send the network interfaces
            };

            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;

            const res = await axios.post(`/api/v1/instanceTemplates/${vmId}`, sendData, options);

            if (res.status !== 201) {
                notify(translate('app.instanceTemplates.create.errorMessage'), { type: 'warning' });

            }
            else {
                notify(translate('app.instanceTemplates.create.successMessage'), { type: 'success' });
                handleCloseClick();
                redirect('list', 'instanceTemplates');
                refresh();
            };
        }
        catch (error) {
            HandleError(error, notify);
        }
    }

    return (
        <>
            <Tooltip title={translate('app.instanceTemplates.create.title')} arrow placement="top">
                <ButtonMui color="primary" onClick={handleClick} className={classes.button} fullWidth={props.fullWidth}
                    style={{
                        color: buttonColor
                    }}>
                    <CloudDownload style={{ color: buttonColor }} />{showTitle ? translate('app.vms.action.createInstanceTemplate') : null}
                </ButtonMui>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.instanceTemplates.create.title')}
            >
                <DialogTitle><SectionField label={translate('app.instanceTemplates.create.title')} icon={CloudDownload} /></DialogTitle>
                <DialogContent>
                    <SimpleForm validate={Validate} toolbar={<CreateToolbar />}
                        defaultValues={{ ...record, fixedNetifs }}
                        onSubmit={instanceTemplateSave} redirect="list"
                    >
                        <SectionField label={translate('app.instanceTemplates.create.data')} icon={Description} />

                        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextInput variant='outlined' source='imageName' label={translate('app.vms.create.imageName')} style={{ minWidth: 250 }} />
                            </Box>
                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                <TextInput variant='outlined' source='description' style={{ minWidth: 250 }}
                                    className={classes.inputMarginLeft} fullWidth multiline />
                            </Box>
                        </Box>

                        <Box className={classes.flexBox}>
                            <SelectInput variant='outlined' source='language' choices={[
                                { id: 'en', name: 'app.users.languages.en' },
                                { id: 'fr', name: 'app.users.languages.fr' },
                            ]} defaultValue={record.language} />
                        </Box>


                        {/* Only display netifs with type 'fixed' */}
                        <h3>Network Interfaces</h3>
                        {fixedNetifs.length > 0 && (
                            <ArrayInput source="fixedNetifs" label="Network Interfaces">
                                <SimpleFormIterator disableRemove disableAdd>
                                    <TextInput label="IP Address" source="addressIp" />
                                    <TextInput label="MAC Address" source="mac_address" />
                                </SimpleFormIterator>
                            </ArrayInput>
                        )}
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
};

const AddSecurityGroupToServer = (props) => {

    const notify = useNotify();
    const record = useRecordContext();
    const refresh = useRefresh();
    const classes = useStyles();
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const Validate = (values) => {
        const errors = {};
        if (!values.securityGroup) errors.securityGroup = 'ra.validation.required';
        return errors;
    };

    const SecurityGroupList = () => {
        const record = useRecordContext();

        const { data, isLoading, error } = useGetList(
            'securityGroups',
            {
                filter: { "pod": record.pod },
                pagination: { page: 1, perPage: 10 },
                sort: { field: 'published_at', order: 'DESC' }
            }
        );

        if (isLoading) { return <Loading />; }
        if (error) { return <p>ERROR</p>; }

        let filteredSecurityGroups = data.filter((securityGroup) => {
            return !record.securityGroups.includes(securityGroup.id);
        });

        return (
            <AutocompleteInput choices={filteredSecurityGroups} source='securityGroup' optionText="name" />
        );
    };



    const CreateToolbar = (props) => {
        return (
            <Toolbar className={classes.toolbar}>
                <Button color='primary' label="ra.action.cancel" size="medium"
                    onClick={handleCloseClick} sx={{ border: '1px solid #6b489d' }}>
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.send')} sx={{ marginLeft: 2 }} />
            </Toolbar>
        );
    };

    const addSecurityGroup = async (data) => {
        try {
            const vmId = record.id;

            const options = {
                headers: {},
            };

            const sendData = { securityGroup: data.securityGroup };
            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;

            const res = await axios.post(`/api/v1/vms/${vmId}/addSecurityGroup`, sendData, options);

            if (res.status !== 201) {
                notify(translate('app.vms.control.addSecurityGroup.errorMessage'), { type: 'error' });
            }
            else {
                notify(translate('app.vms.control.addSecurityGroup.successMessage'), { type: 'success' });
                handleCloseClick();
                refresh();
            };
        }
        catch (error) {
            HandleError(error, notify);
        };
    };

    return (
        <>
            <Tooltip title={translate('app.vms.control.addSecurityGroup.title')} arrow placement="top">
                <ButtonMui color="primary" onClick={handleClick}>
                    <Lan />{translate('app.vms.action.addSecurityGroup')}
                </ButtonMui>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.vms.control.addSecurityGroup.title')}
            >
                <DialogTitle>{translate('app.vms.control.addSecurityGroup.title')}</DialogTitle>
                <DialogContent>
                    <SimpleForm validate={Validate} toolbar={<CreateToolbar />} onSubmit={addSecurityGroup} >
                        <SectionField label={translate('app.vms.control.addSecurityGroup.data')} icon={Description} />
                        <SecurityGroupList />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
};

const RemoveSecurityGroupFromServer = (props) => {

    const notify = useNotify();
    const record = useRecordContext();
    const refresh = useRefresh();
    const classes = useStyles();
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const Validate = (values) => {
        const errors = {};
        if (!values.securityGroup) errors.securityGroup = 'ra.validation.required';
        return errors;
    };

    const SecurityGroupList = () => {
        const record = useRecordContext();

        const { data, isLoading, error } = useGetList(
            'securityGroups',
            {
                filter: { "pod": record.pod },
                pagination: { page: 1, perPage: 10 },
                sort: { field: 'published_at', order: 'DESC' }
            }
        );

        if (isLoading) { return <Loading />; }
        if (error) { return <p>ERROR</p>; }

        let filteredSecurityGroups = data.filter((securityGroup) => {
            return record.securityGroups.includes(securityGroup.id);
        });


        return (
            <AutocompleteInput choices={filteredSecurityGroups} source='securityGroup' optionText="name" />
        );
    };

    const CreateToolbar = (props) => {
        return (
            <Toolbar className={classes.toolbar}>
                <Button color='primary' label="ra.action.cancel" size="medium"
                    onClick={handleCloseClick} sx={{ border: '1px solid #6b489d' }}>
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.send')} sx={{ marginLeft: 2 }} />
            </Toolbar>
        );
    };

    const removeSecurityGroup = async (data) => {
        try {
            const vmId = record.id;

            const options = {
                headers: {},
            };

            const sendData = { securityGroup: data.securityGroup };
            const token = localStorage.getItem('token');
            options.headers.Authorization = `Bearer ${token}`;

            const res = await axios.post(`/api/v1/vms/${vmId}/removeSecurityGroup`, sendData, options);

            if (res.status !== 200) {
                notify(translate('app.vms.control.removeSecurityGroup.errorMessage'), { type: 'error' });
            }
            else {
                notify(translate('app.vms.control.removeSecurityGroup.successMessage'), { type: 'success' });
                handleCloseClick();
                refresh();
            };
        }
        catch (error) {
            HandleError(error, notify);
        };
    };

    return (
        <>
            <Tooltip title={translate('app.vms.control.removeSecurityGroup.title')} arrow placement="top">
                <ButtonMui color="primary" onClick={handleClick}>
                    <Lan />{translate('app.vms.action.removeSecurityGroup')}
                </ButtonMui>
            </Tooltip>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.vms.control.removeSecurityGroup.title')}
            >
                <DialogTitle>{translate('app.vms.control.removeSecurityGroup.title')}</DialogTitle>
                <DialogContent>
                    <SimpleForm validate={Validate} toolbar={<CreateToolbar />} onSubmit={removeSecurityGroup}>
                        <SectionField label={translate('app.vms.control.removeSecurityGroup.data')} icon={Description} />
                        <SecurityGroupList />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    )
};

export {
    ControlVm, RebootVm, ResizeVm, CreateVmInterface, RemoveVmInterface,
    OpenVmConsole, CreateInstanceTemplate, AddSecurityGroupToServer,
    RemoveSecurityGroupFromServer, StartAllVms, StopAllVms, OpenGuacConsole
};