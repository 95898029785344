import { Fragment, useState } from 'react';
import {
    SimpleForm, useTranslate,
    Toolbar, SaveButton, ReferenceInput,
    Button, AutocompleteInput, useRecordContext, CheckboxGroupInput,
    ReferenceArrayInput, useNotify, useRefresh,
} from 'react-admin';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { Launch, Add } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { HandleError, ViewTitle } from '../../components';
import { Cancel } from '@mui/icons-material';
import CustomReferenceInput from '../../custom/customReferenceInput';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    spacer: {
        flex: 1,
    },
    buttonMarginLeft: {
        marginLeft: 10
    },
    flexBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    deployButton: {
        display: 'flex',
        justifyContent: 'right',
    },
    inputMarginLeft: {
        marginLeft: 20
    },
    border: {
        border: '1px solid #6b489d'
    },
    templateInput: {
        width: '300px',  /* Adjust this to your desired width */
    },
}));

const Validate = (values) => {
    const errors = {};
    if (!values.name) errors.name = 'ra.validation.required';
    if (!values.pod) errors.pod = 'ra.validation.required';
    return errors;
};

const DeployUserResources = ({ permissions, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const optionRenderer = choice => `${choice.name}  #${choice.language} #${choice.snapshotVersion}`;
    const filterToQuery = searchText => ({ name: `${searchText}` });

    const DeployTemplate = async (data) => {
        const pods = data.pod;

        pods.forEach(async (pod) => {
            try {
                const options = {
                    headers: {},
                };

                const sendData = {
                    pod: pod,
                    instanceTemplate: data.instanceTemplate
                };

                const token = localStorage.getItem('token');
                options.headers.Authorization = `Bearer ${token}`;

                handleCloseClick();

                const res = await axios.post(`/api/v1/instanceTemplates/deploy`, sendData, options);

                if (res.status !== 201) {
                    notify(translate('app.instanceTemplates.deploy.errorMessage'), { type: 'error' });
                }
                else {
                    notify(translate('app.instanceTemplates.deploy.successMessage'), { type: 'success' });
                    //handleCloseClick();
                    refresh();
                };
            }
            catch (error) {
                HandleError(error, notify);
            };
        });
    };


    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };


    const CreateToolbar = (props) => {
        const classes = useStyles();
        const translate = useTranslate();
        return (
            <Toolbar className={classes.toolbar}>
                <Button label="ra.action.cancel" size="medium" onClick={handleCloseClick} className={classes.border} >
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.deploy')} className={classes.buttonMarginLeft} />
            </Toolbar>
        );
    };

    return (
        <Fragment>
            <Button label={translate('app.trainingTemplates.deployUserResources.button')}
                onClick={handleClick} className={classes.deployButton} ><Add /></Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.instanceTemplates.deploy.title')}
            >
                <DialogTitle> <ViewTitle label={translate('app.instanceTemplates.deploy.title')} icon={Launch} /></DialogTitle>
                <DialogContent>

                    <SimpleForm validate={Validate} toolbar={<CreateToolbar />}
                        onSubmit={DeployTemplate} >

                        <CustomReferenceInput
                            source="instanceTemplate"
                            reference="instanceTemplates"
                            label={translate('app.instanceTemplates.title')}
                            filter={{ podType: 'user' }}
                            optionText={optionRenderer}
                            className={classes.templateInput}
                            filterToQuery={filterToQuery}
                        />

                        <ReferenceArrayInput
                            source="pod"
                            reference="pods"
                            label="pod"
                            filter={{ "room": props.room, "type": 'user' }}
                        >
                            <CheckboxGroupInput source="options" />
                        </ReferenceArrayInput>
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default DeployUserResources;

