const determineSubnet = (ipAddress) => {
    // Split the IP address into parts using the dot as a separator
    const parts = ipAddress.split('.');

    // Check the first three segments of the IP address
    const subnetIdentifier = parts.slice(0, 3).join('.');

    // Determine subnet based on the first three parts of the IP address
    if (subnetIdentifier === '10.20.30') {
        return 'Subnet0'
    } else if (subnetIdentifier === '192.168.1') {
        return 'Subnet1';
    } else if (subnetIdentifier === '192.168.2') {
        return 'Subnet2';
    } else if (subnetIdentifier === '192.168.3') {
        return 'Subnet3';
    } else {
        return 'Unknown Subnet';
    }
};

export default determineSubnet;