import { CircularProgress, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Fragment, useEffect, useState } from 'react';
import {
    Show, TextField, TabbedShowLayout, Tab,
    Datagrid, ReferenceField, ReferenceManyField,
    DeleteWithConfirmButton, ShowButton, useTranslate, FunctionField,
    useShowController, TopToolbar, ReferenceArrayField, EditButton,
    CreateButton, List, usePermissions, useRecordContext,
    useDataProvider
}
    from 'react-admin';
import BasicBreadcrumbs from '../../components/breadcrumb';
import AssociateUserToPod from '../pods/associatePodToUser';
import DisAssociateUserToPod from '../pods/disassociatePodToUser';
import AssociateTrainerToTraining from '../trainings/associateTrainerToTraining';
import DisassociateTrainer from '../trainings/disassociateTrainer';
import BecomeUser from '../users/becomeUser';
import DeployNewPodUser from './deployNewPodUser';
import DeployCommonResources from './deployCommonResources';
import DeployUserResources from './deployUserResources';
import GlobalAssociateUserToPod from '../pods/globalAssociatePodToTrainees';
import Button from '@mui/material/Button';
import { Visibility } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import ClearClassroom from './clearClassroom';
import { HasPermissions } from '../../components';

const useStyles = makeStyles((theme) => ({
    tableLastCell: {
        width: '50%',
        textAlign: 'right'
    },
    endCell: {
        width: '10%',
        marginLeft: '85%',
    },
    descriptionCell: {
        width: '20%'
    },
    nameCell: {
        whiteSpace: 'nowrap',
    },
    topToolbar: {
        minHeight: 2
    },
    lowToolbar: {
        minHeight: 2,
        padding: 0
    },
    button: {
        //     minWidth: '10px',
        //     width: '36px',
        //     height: '32px'
    },
    nameLinkCell: {
        width: '150px',  // Adjust width for name column
        maxWidth: '150px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        font: 'inherit',
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
}));

const useVmCount = (podId) => {
    const dataProvider = useDataProvider();
    const [vmCount, setVmCount] = useState(null);

    useEffect(() => {
        const fetchVmCount = async () => {
            try {
                const { total } = await dataProvider.getList('vms', {
                    filter: { pod: podId, type: { $ne: "firewall" } },
                    pagination: { page: 1, perPage: 1 },
                    sort: { field: 'id', order: 'ASC' },
                });
                setVmCount(total);
            } catch (error) {
                console.error(`Failed to fetch VM count for pod ${podId}:`, error);
                setVmCount(0); // Default to 0 if fetching fails
            }
        };

        fetchVmCount();
    }, [dataProvider, podId]);
    return vmCount;
};

const VmCountField = ({ source }) => {
    const record = useRecordContext();
    const vmCount = useVmCount(record[source]);

    if (vmCount === null) {
        return <CircularProgress size={20} />;
    }

    return <span>{vmCount}</span>;
};

const ClassroomShow = ({ ...props }) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const { record, isLoading, error } = useShowController({ resource: 'classrooms', id: props.id });
    const CustomEmpty = () => <div>{translate('app.emptyMessage')}</div>;
    const classes = useStyles();
    const fullNameField = choice => `${choice.firstname} ${choice.lastname}`;
    const [showError, setShowError] = useState(false); // State to track error visibility

    const toggleErrorVisibility = () => {
        setShowError(prevState => !prevState); // Toggle the visibility state
    };

    if (isLoading) {
        return <div>{translate('app.LoadingMessage')}</div>;
    };

    if (error) {
        return <div>{translate('app.ErrorMessage')}</div>;
    };

    const ClassroomShowActions = () => {
        return (
            <TopToolbar className={classes.topToolbar}>
            </TopToolbar>
        )
    };

    const ClassroomActions = () => {
        const translate = useTranslate();
        const record = useRecordContext();
        const { permissions } = usePermissions();

        let title = `${translate('app.classrooms.delete.title')}`;

        if (record && record.name) {
            title = `${translate('app.classrooms.delete.title')} ${record.name}`;
        }

        if (permissions.includes('admin')) {
            return (
                <TopToolbar className={classes.lowToolbar}>
                    <EditButton />
                    <ClearClassroom />
                    <DeleteWithConfirmButton confirmContent={translate('app.classrooms.delete.confirmMessage')}
                        confirmTitle={title} />
                </TopToolbar>
            )
        } else {
            return (<ClassroomShowActions />);
        }
    };

    const ListActionButtons = () => {
        const translate = useTranslate();
        const record = useRecordContext();

        const title = `${translate('app.pods.delete.title')} ${record.name}`;
        const location = useLocation();

        return (
            <Fragment>
                <Button size='small' className={classes.button}
                    component={Link}
                    to={`${location.pathname}/${record.id}`}
                    startIcon={<Visibility />}
                >show</Button>
                <DeleteWithConfirmButton confirmContent={translate('app.pods.delete.confirmMessage')}
                    confirmTitle={title} />
            </Fragment>
        );
    };

    const ListActionButtonsForUserPod = () => {
        const translate = useTranslate();
        const record = useRecordContext();
        const location = useLocation();

        const title = `${translate('app.pods.delete.title')} ${record.name}`;
        return (
            <Fragment>
                <AssociateUserToPod />
                <Button size='small' className={classes.button}
                    component={Link}
                    to={`${location.pathname}/${record.id}`}
                    startIcon={<Visibility />}
                >{translate('app.pods.show')}</Button>
                <DeleteWithConfirmButton confirmContent={translate('app.pods.delete.confirmMessage')}
                    confirmTitle={title} redirect={false} />
            </Fragment>
        );
    };

    const UserListActionButtons = () => {
        return (
            <Fragment>
                <BecomeUser />
                <DisAssociateUserToPod />
                <ShowButton />
            </Fragment>
        );
    };

    const TrainerListActionButtons = () => {
        return (
            <Fragment>
            </Fragment>
        );
    };

    const TraineeListActionButtons = () => {
        const translate = useTranslate();
        return (
            <Fragment>
                <CreateButton label={translate('app.users.titles.create')} className={classes.endCell} />
                <GlobalAssociateUserToPod id={record.id} />
            </Fragment>
        );
    };

    // <GlobalAssociateUserToPod id={record.id} />

    const TrainingActionButtons = () => {
        return (
            <Fragment>
                <AssociateTrainerToTraining />
                <DisassociateTrainer />
                <EditButton />
            </Fragment>
        );
    };

    const UserDeployButtons = () => {
        return (
            <>
                {HasPermissions('admin') || HasPermissions('designer') ?

                    <Grid container style={{ display: "flex", justifyContent: 'right' }}>
                        <Grid item>
                            <DeployUserResources room={record.id} />
                        </Grid>
                        <Grid item alignItems="stretch" style={{ display: "flex" }}>
                            <DeployNewPodUser trainingTemplate={record.training_template} room={record.id} />
                        </Grid>
                    </Grid >
                    :
                    <> </>
                }
            </>
        )
    };

    return (
        <>
            {props.myclassroom ?
                null : props.showTraining ?
                    null : record ? <BasicBreadcrumbs name={record.name} /> : ''}
            <Show {...props} actions={<ClassroomActions />} >

                <TabbedShowLayout>

                    <Tab label='General'>

                        <TextField source='name' />

                        {!isLoading && record.training_template && !permissions.includes("trainer") ?
                            <ReferenceField source='training_template' reference='trainingTemplates' link='show'>
                                <TextField source='name' label={translate('app.classrooms.name')} />
                            </ReferenceField>
                            : " "
                        }

                        {record && record.stackError && (
                            <>
                                <Button onClick={toggleErrorVisibility}>
                                    {showError
                                        ? translate('app.vms.hideError')
                                        : translate('app.vms.showError')}
                                </Button>
                                {showError && (
                                    <TextField
                                        source="stackError"
                                        label={translate('app.vms.show.error')}
                                    />
                                )}
                            </>
                        )}

                        {!isLoading && record.training ?

                            <ReferenceManyField source='training' reference='trainings' target='_id' id='id' link='list' >
                                <Datagrid bulkActionButtons={false} sx={{
                                    '& .RaDatagrid-tableWrapper': {
                                        width: '100%',
                                    },
                                }}>
                                    <TextField source='name' label={translate('app.classrooms.name')} />
                                    <TextField source='locator' label={translate('app.classrooms.locator')} />
                                    <ReferenceArrayField source='trainer' reference='users' cellClassName={classes.nameCell} label={translate('app.classrooms.trainer')} >
                                        <Datagrid bulkActionButtons={false}>
                                            <FunctionField render={fullNameField} />
                                            <TrainerListActionButtons cellClassName={classes.tableLastCell} />
                                        </Datagrid>
                                    </ReferenceArrayField>
                                    {permissions.includes("trainer") ? null : <TrainingActionButtons cellClassName={classes.tableLastCell} />}
                                </Datagrid>
                            </ReferenceManyField>
                            : " "
                        }

                        {!isLoading && record.training ?

                            <List resource='users' actions={<TraineeListActionButtons />} pagination={false} >
                                <ReferenceManyField source='training' reference='trainings' target='_id' id='id' link='list' label='false'>
                                    <Datagrid empty={<CustomEmpty />} bulkActionButtons={false} >
                                        <ReferenceArrayField
                                            label={translate('app.classrooms.trainees')}
                                            source='trainees'
                                            reference="users"
                                            target="classroom"
                                        >
                                            <Datagrid empty={<CustomEmpty />} bulkActionButtons={false} sx={{
                                                '& .RaDatagrid-tableWrapper': {
                                                    width: '60%',
                                                },
                                            }}>
                                                <TextField source="firstname" label={translate('app.classrooms.firstname')} />
                                                <TextField source="lastname" label={translate('app.classrooms.lastname')} />
                                                <TextField source="email" cellClassName={classes.nameCell} label={translate('app.classrooms.email')} />
                                                <ReferenceField source='pod' reference='pods' cellClassName={classes.nameCell} >
                                                    <TextField source='name' />
                                                </ReferenceField>
                                                <UserListActionButtons cellClassName={classes.tableLastCell} />
                                            </Datagrid>
                                        </ReferenceArrayField>
                                    </Datagrid>
                                </ReferenceManyField>
                            </List>

                            : " "
                        }


                    </Tab>
                    <Tab label='Pod' path='Pod'>

                        <ReferenceManyField
                            label={<h1>{translate('app.pods.common')}</h1>}
                            perPage={15}
                            reference="pods"
                            target="room"
                            sort={{
                                field: 'number',
                                order: 'ASC'
                            }}
                            filter={{
                                type: "common"
                            }}

                        >
                            <Grid container style={{ display: "flex", justifyContent: 'right' }}>
                                {permissions.includes("admin") || permissions.includes("designer") ?
                                    <Grid item>
                                        <DeployCommonResources room={record.id} />
                                    </Grid>
                                    :
                                    <> </>
                                }
                            </Grid>

                            <Datagrid empty={<CustomEmpty />}>
                                <TextField source="number" label={translate('app.classrooms.number')} />
                                <FunctionField
                                    label={translate(`app.pods.name`)}
                                    render={record => (
                                        <Link to={`/pods/${record.id}/show`} className={classes.nameLinkCell}>
                                            {record.name}
                                        </Link>
                                    )}
                                />
                                <TextField source="description" cellClassName={classes.descriptionCell} label={translate('app.classrooms.description')} />
                                <VmCountField source="id" cellClassName={classes.descriptionCell} label={translate('app.classrooms.numberOfVMs')} />
                                <ListActionButtons cellClassName={classes.tableLastCell} />
                            </Datagrid>

                        </ReferenceManyField>

                        <ReferenceManyField
                            label={<h1>{translate('app.pods.user')}</h1>}
                            perPage={15}
                            reference="pods"
                            target="room"
                            sort={{
                                field: 'number',
                                order: 'ASC'
                            }}
                            filter={{
                                type: "user"
                            }}
                        >
                            <UserDeployButtons />
                            <Datagrid empty={<CustomEmpty />}>
                                <TextField source="number" label={translate('app.classrooms.number')} />
                                <FunctionField
                                    label={translate(`app.pods.name`)}
                                    render={record => (
                                        <Link to={`/pods/${record.id}/show`} className={classes.nameLinkCell}>
                                            {record.name}
                                        </Link>
                                    )}
                                />
                                <TextField source="description" cellClassName={classes.descriptionCell} label={translate('app.classrooms.description')} />
                                <ReferenceManyField
                                    label={translate('app.classrooms.trainees')}
                                    reference="users"
                                    target="pod"
                                >
                                    <Datagrid empty={<CustomEmpty />} bulkActionButtons={false}>
                                        <TextField source="firstname" label={translate('app.classrooms.firstname')} />
                                        <TextField source="lastname" label={translate('app.classrooms.lastname')} />
                                        <BecomeUser />
                                        <DisAssociateUserToPod cellClassName={classes.tableLastCell} />
                                    </Datagrid>
                                </ReferenceManyField>
                                <VmCountField source="id" cellClassName={classes.descriptionCell} label={translate('app.classrooms.numberOfVMs')} />
                                <ListActionButtonsForUserPod cellClassName={classes.tableLastCell} />
                            </Datagrid>
                        </ReferenceManyField>
                    </Tab>
                </TabbedShowLayout>
            </Show >
        </>
    );
};

export default ClassroomShow;