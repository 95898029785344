import React, { useState, useEffect, useCallback } from 'react';
import { useGetList, useInput, useGetOne } from 'react-admin';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';

const CustomReferenceInput = ({
    source,
    reference,
    label,
    filter,
    optionText,
    className,
    filterToQuery = searchText => ({ name: searchText }),
}) => {
    const [page, setPage] = useState(1);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [searchText, setSearchText] = useState('');

    const {
        field: { onChange, value },
        fieldState: { error: fieldError },
        formState: { isSubmitting }
    } = useInput({ source });

    // Safely extract error message from various possible formats
    const getErrorMessage = (error) => {
        if (!error) return '';
        if (typeof error === 'string') return error;
        if (error.message && typeof error.message === 'string') return error.message;
        if (Array.isArray(error)) return error[0]?.message || error[0] || '';
        return 'An error occurred';
    };

    const errorMessage = getErrorMessage(fieldError);

    const { data: selectedRecord } = useGetOne(reference, { id: value }, {
        enabled: !!value && !options.find(o => o.id === value)
    });

    const currentFilter = {
        ...filter,
        ...(searchText ? filterToQuery(searchText) : {})
    };

    const { data, total, isLoading } = useGetList(
        reference,
        {
            pagination: { page, perPage: 25 },
            sort: { field: 'name', order: 'ASC' },
            filter: currentFilter
        }
    );

    useEffect(() => {
        if (data) {
            const newData = Object.values(data);
            setOptions(prevOptions => {
                let combinedOptions;
                if (page === 1) {
                    combinedOptions = selectedRecord
                        ? [...newData.filter(item => item.id !== selectedRecord.id), selectedRecord]
                        : newData;
                } else {
                    combinedOptions = [...prevOptions, ...newData];
                }
                // Deduplicate based on id
                const uniqueOptions = Array.from(
                    new Map(combinedOptions.map(item => [item.id, item])).values()
                );

                return uniqueOptions;
            });
        }
    }, [data, page, selectedRecord]);


    const handleClear = useCallback(() => {
        onChange(null);
        setInputValue('');
        setSearchText('');
        setOptions([]);
        setPage(1);
    }, [onChange]);

    const handleChange = useCallback((event, newValue) => {
        onChange(newValue ? newValue.id : null);
        setInputValue(newValue ? getOptionLabel(newValue) : '');
        if (!newValue) {
            handleClear();
        }
    }, [onChange, handleClear]);

    const handleScroll = useCallback((event) => {
        const list = event.target;
        const scrollPosition = Math.abs(list.scrollHeight - list.scrollTop - list.clientHeight);

        if (scrollPosition < 50 && options.length < total && !isLoading) {
            setPage(prev => prev + 1);
        }
    }, [options.length, total, isLoading]);

    const handleInputChange = useCallback((event, newInputValue) => {
        setInputValue(newInputValue);
        setTimeout(() => {
            setSearchText(newInputValue);
            setPage(1);
        }, 300);
    }, []);

    const getOptionLabel = useCallback((option) => {
        if (!option) return '';
        return typeof optionText === 'function'
            ? optionText(option)
            : option[optionText] || '';
    }, [optionText]);    

    const currentValue = value ? (options.find(o => o.id === value) || selectedRecord) : null;

    return (
        <Autocomplete
            value={currentValue}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            options={options}
            getOptionLabel={getOptionLabel}
            loading={isLoading}
            className={className}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    error={!!errorMessage}
                    helperText={errorMessage}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            ListboxProps={{
                onScroll: handleScroll,
                style: {
                    maxHeight: '300px'
                }
            }}
            filterOptions={(x) => x}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(props, option) => (
                // Use the unique id as key, but display only the label returned by getOptionLabel.
                <li {...props} key={option.id}>
                    {getOptionLabel(option)}
                </li>
            )}
            noOptionsText={isLoading ? "Loading..." : "No options found"}
            clearOnBlur={false}
            clearOnEscape={false}
        />
    );
};

export default CustomReferenceInput;