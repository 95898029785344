import { Fragment, useState } from 'react';
import {
    SimpleForm, useTranslate,
    Toolbar, SaveButton, useCreate, ReferenceInput,
    useNotify, useRefresh,
    Button, AutocompleteInput,
    useListContext, RadioButtonGroupInput, FormDataConsumer, useGetIdentity,
} from 'react-admin';

import { SectionField } from '../../components';
import { makeStyles } from '@mui/styles';
import { Cancel, Send } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import io from 'socket.io-client';
import CustomReferenceInput from '../../custom/customReferenceInput';
import { Box } from '@mui/material';

const BackendURL = process.env.REACT_APP_PROGRESS_URL; // Replace with your backend URL

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    spacer: {
        flex: 1,
    },
    buttonMarginLeft: {
        marginLeft: 10
    },
    flexBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputMarginLeft: {
        marginLeft: 40
    },
    deployButton: {
        display: 'flex !important',
        justifyContent: 'right !important',
    },
    color: {
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        border: theme.palette.primary.main
    },
    templateInput: {
        width: '300px',  /* Adjust this to your desired width */
    },
}));

const Validate = (values) => {
    const errors = {};
    //if (!values.trainingTemplate) errors.trainingTemplate = 'ra.validation.required';
    if (!values.choice) errors.choice = 'ra.validation.required';
    return errors;
};



const DeployCommonResources = (props) => {
    const [create] = useCreate();
    const notify = useNotify();
    const refresh = useRefresh();
    const classes = useStyles();
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);
    const { data: podData } = useListContext();
    const optionRenderer = choice => `${choice.name}  #${choice.language} #${choice.snapshotVersion}`;
    const optionRendererTrainingTemplate = choice => `${choice.name} #${choice.language} #${choice.step}`;
    const [socket, setSocket] = useState(null); // State to hold the socket reference
    const { data: identity, isLoading, error } = useGetIdentity();
    const [deployments, setDeployments] = useState({}); // Store deployment state for each tab
    const [showProgressDialog, setShowProgressDialog] = useState(false); // New dialog state
    const [progressData, setProgressData] = useState({ progress: 0, statusText: '' });
    const { id: userId } = identity;
    const filterToQuery = searchText => ({ name: `${searchText}` });

    const CreateToolbar = () => {

        return (
            <Toolbar className={classes.toolbar}>
                <Button color='primary' label="ra.action.cancel" size="medium" onClick={handleCloseClick} className={classes.color}>
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.deploy')} className={classes.buttonMarginLeft} />
            </Toolbar>
        );
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const trainingTemplateDeploy = (data) => {


        if (data.choice === 'trainingTemplate') {

            handleShowProgressDialog();

            let dataToSend = {
                "room": props.room,
                "trainingTemplate": data.trainingTemplate,
            };

            create(`trainingTemplates/deployCommonResources`, { data: dataToSend }, {
                onSuccess: response => {

                    if (!response) {
                        notify('ra.notification.created', 'info');

                    } else {
                        notify(translate('app.trainingTemplates.deployCommonResources.successMessage'), { type: 'success' });
                        //redirect('list', 'classrooms');
                        refresh();
                    }
                },
                onError: error => {
                    if (error.message) {
                        notify(`${translate('app.trainingTemplates.deployCommonResources.errorMessage')}: ${error.message}`, { type: 'error' });
                    }
                    else {
                        notify(translate('app.trainingTemplates.deployCommonResources.errorMessage'), { type: 'error' });
                    }
                }
            });
            handleCloseClick();
        }
        else if (data.choice === 'instanceTemplate') {

            let dataToSend;

            if (podData?.length > 0) {
                dataToSend = {
                    pod: podData[0].id,
                    instanceTemplate: data.instanceTemplate
                };


                create(`instanceTemplates/deploy`, { data: dataToSend }, {
                    onSuccess: response => {

                        if (!response) {
                            notify('ra.notification.created', 'info');

                        } else {
                            notify(translate('app.instanceTemplates.deploy.successMessage'), { type: 'success' });
                            //redirect('list', 'classrooms');
                            refresh();
                        }
                    },
                    onError: error => {
                        if (error.message) {
                            notify(`${translate('app.instanceTemplates.deploy.errorMessage')}: ${error.message}`, { type: 'error' });
                        }
                        else {
                            notify(translate('app.instanceTemplates.deploy.errorMessage'), { type: 'error' });
                        }
                    }
                });

            } else {
                //handleCloseClick();
                notify("Pod not found", 'error');
            };


            handleCloseClick();
        };

    };


    const handleShowProgressDialog = async () => {
        if (!userId) {
            // If userId is not available, return early
            return;
        }

        const socket = io(BackendURL, {
            query: { userId }, // Pass the userId as a query parameter
        });

        socket.on('connect', () => {
            console.log('WebSocket connection established.');
        });

        socket.on('disconnect', () => {
            console.log('WebSocket connection closed.');
        });

        socket.emit('joinRoom', userId);

        // Event handler for 'deploymentProgress' event from the backend
        socket.on('deploymentProgress', ({ userId, progress, statusText, trainingId, status }) => {

            // Update the progress and statusText in the component state
            setProgressData({ progress, statusText });

            // Open the progress dialog when the event is received
            setShowProgressDialog(true);


            const updatedDeployments = { ...deployments };
            updatedDeployments[userId] = { progress, statusText, status };
            setDeployments(updatedDeployments);
        });

    };

    return (
        <Fragment>
            <Button label={translate('app.trainingTemplates.deployCommonResources.button')} className={classes.deployButton}
                onClick={handleClick}><Send /></Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.trainingTemplates.deployCommonResources.title')}
            >
                <DialogTitle> <SectionField label={translate('app.trainingTemplates.deployCommonResources.title')} icon={Send} /></DialogTitle>
                <DialogContent>
                    <SimpleForm validate={Validate} toolbar={<CreateToolbar />} onSubmit={trainingTemplateDeploy} redirect="list">
                        <RadioButtonGroupInput variant='outlined' source='choice' choices={[
                            { id: 'trainingTemplate', name: 'Training Template' },
                            { id: 'instanceTemplate', name: 'Instance Template' },
                        ]} />

                        <FormDataConsumer>
                            {({ formData, ...rest }) => (
                                formData.choice === 'trainingTemplate'
                                    ?
                                    <>
                                        <Box className={classes.flexBox} sx={{ mb: 4 }}>
                                            <CustomReferenceInput
                                                source="trainingTemplate"
                                                reference="trainingTemplates"
                                                label={translate('app.trainingTemplates.title')}
                                                optionText={optionRendererTrainingTemplate}
                                                className={classes.templateInput}
                                                filterToQuery={filterToQuery}
                                            />
                                        </Box>
                                    </>
                                    :
                                    <>
                                        <Box className={classes.flexBox} sx={{ mb: 4 }}>
                                            <CustomReferenceInput
                                                source="instanceTemplate"
                                                reference="instanceTemplates"
                                                label={translate('app.instanceTemplates.title')}
                                                filter={{ podType: 'common' }}
                                                optionText={optionRenderer}
                                                className={classes.templateInput}
                                                filterToQuery={filterToQuery}
                                            />
                                        </Box>
                                    </>
                            )}
                        </FormDataConsumer>

                    </SimpleForm>
                </DialogContent>
            </Dialog>
            {/* Render your progress bar or other UI elements */}
            {/* New Dialog to show progress and status */}
            {Object.keys(deployments).map((userId) => (
                <Dialog
                    key={userId}
                    fullWidth
                    open={true} // Show dialog based on deployment state
                    onClose={() => {
                        const updatedDeployments = { ...deployments };
                        delete updatedDeployments[userId];
                        setDeployments(updatedDeployments);
                    }}
                    aria-label="Deployment Progress"
                >
                    <DialogTitle>
                        <SectionField label='Deployment Progress' icon={Send} />
                    </DialogTitle>
                    <DialogContent>
                        <h1>Progress: {deployments[userId].progress}%</h1>
                        <h1>Status: {deployments[userId].status}</h1>
                        <h1>Step: {deployments[userId].statusText}</h1>
                    </DialogContent>
                </Dialog>
            ))}
        </Fragment>
    )
};

export default DeployCommonResources;
